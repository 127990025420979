/**
 * Direttiva che costruisce una card rapprensentante un corso
 */
import { Item, User, Course, CourseAttribute } from "atfcore-commonclasses";

export interface ICourseCardDirectiveScope extends ng.IScope {
	courseCard: any;
	goToCourseDetail: Function;
	goToWebinarDetail: Function;
	chartOptions: any;
	isSoftSkill: boolean;
	isTechSkill: boolean;
	isMifid: boolean;
	itemType: any;
	creditType: string;
	creditValue: string;
	techCompetences: any;
	softCompetences: any;
	hasSuggested: boolean;
	globalApplicationData: any;
	bgacademyApplicationData: any;
	suggestedPerson: any;
	isLearningPlan: boolean;
	page: any;
	course: any;
	isItemOpen: boolean;
	percentageItem: boolean;
	suggestedFromAdmin: boolean;
	enableOrangeColor: Function;
	enableRedColor: Function;
	enableGreyColor: Function;
	isCourseLink: boolean;
	isCourseOnline: boolean;
	isCourseOnPremise: boolean;
	isUserStatusPresent: Function;
	isSurveyReady: Function;
	isThereValidSurveyTemplate: Function;
	bottomVisible: boolean;
	isUserStatusInvited: Function;
	isUserStatusConfirmed: Function;
	isUserStatusRefused: Function;
	isUserPresentAndSurveyToStart: Function;
	isUserPresentAndSurveyToContinue: Function;
	isUserPresentAndHasNoSurveyToDo: Function;
	isUserPresentAndHasPassedTheSurvey: Function;
	isStopDateExceeded: Function;
	isEndDateExceeded: Function;
	isUserPresentAndSurveyPassedOrAbsentAndCertificateReady: Function;
	isUserPresentAndSurveyNotPassed: Function;
	mifidValue: string;
	isIvass: boolean;
	ivassValue: string;
}
angular.module('app').directive("courseCard", ($window, $state, BgacademyApplicationData, GlobalApplicationData, $translate) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			page: '@',
			courseCard: '=',
			chartOptions: '=',
			openCourseDetail: '&',
			bottomVisible: '='
		},
		link: link,
		templateUrl: 'app/shared/courseCard/courseCard.html'
	};
	function link($scope: ICourseCardDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento agli oggetti principali
		$scope.globalApplicationData = GlobalApplicationData;
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		// Item passato alla Direttiva. Se esiste il referenceId significa che si tratta 
		// di un childItem
		$scope.course = $scope.courseCard;

		// Verifica se il corso è online, in aula o è solo un link
		$scope.isCourseLink = !!($scope.course.courseAttributes && ($scope.course.courseAttributes.filter((ca: CourseAttribute) => ca.courseAttributeType === $scope.bgacademyApplicationData.constants.REMOTE_PLATFORM_COURSE_LINK).length));
		$scope.isCourseOnline = $scope.course.courseType == $scope.bgacademyApplicationData.constants.ONLINE ? true : false;
		$scope.isCourseOnPremise = $scope.course.courseType == $scope.bgacademyApplicationData.constants.ONLINE ? false : true;

		// Elenco di tag delle competenze tecniche
		$scope.techCompetences = [];

		// Elenco di tag delle competenze soft
		$scope.softCompetences = [];

		// Tipo dell'oggetto (video, audio, e-book...)
		$scope.itemType = null;

		// Verifica se è una competenza tecnica
		$scope.isTechSkill = false;

		// Verifica se è ivass
		$scope.isIvass = false;

		// Valore ivass
		$scope.ivassValue = null;

		// Verifica se è MiFID
		$scope.isMifid = false;

		// Valore MiFID
		$scope.mifidValue = null;

		// Verifica se è una competenza soft
		$scope.isSoftSkill = false;

		// Tipo e Valore crediti
		$scope.creditType = null;
		$scope.creditValue = null;

		// Oggetto aperto
		$scope.isItemOpen = false;

		// Oggetto iniziato
		$scope.percentageItem = false;

		// Persona che ha suggerito l'elemento
		$scope.suggestedPerson = null;

		// Suggerito dall'amminsitrazione
		$scope.suggestedFromAdmin = false;

		// Configurazione chart con la percentuale del completamento piano
		$scope.chartOptions = {
			animate: {
				duration: 800,
				enabled: true
			},
			barColor: '#1abc9c',
			scaleColor: '',
			trackColor: '#fff',
			lineWidth: 2,
			lineCap: 'butt',
			size: 75
		}
		// Cambio la classe del titolo in base alla sua lunghezza, così diminuendo il font-size evito che sfori
		if ($scope.course && $scope.course.courseTitle && $scope.course.courseTitle.length < 62) {
			$scope.course.titleClass = 'card-title-h3';
		} else if ($scope.course && $scope.course.courseTitle && $scope.course.courseTitle.length > 62) {
			$scope.course.titleClass = 'card-title-h2';
		}

		// Verifica se l'utente è in stato presente
		$scope.isUserStatusPresent = () => {
			if ($scope.course.userStatus && $scope.course.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_PRESENT) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente è presente e non ha nessun test da fare
		$scope.isUserPresentAndHasNoSurveyToDo = () => {
			if ($scope.isUserStatusPresent() && !$scope.isThereValidSurveyTemplate()) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente è presente e ha passato il test
		$scope.isUserPresentAndHasPassedTheSurvey = () => {
			if ($scope.isUserStatusPresent() && $scope.isThereValidSurveyTemplate() && $scope.course.surveyStatus && $scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.PASSED) {
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore grigio, cioè qualora ci sia una verifica completata o non ci sia affatto (quindi neppure un template survey) oppure il corso sia scaduto e l'utente si trovi negli stati INVITATO, CONFERMANTO o RIFIUTATO
		$scope.isUserPresentAndSurveyPassedOrAbsentAndCertificateReady = () => {
			if ($scope.course.objDisabled == true || ($scope.isUserStatusPresent() && (($scope.course.surveyStatus && $scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.PASSED) || !$scope.isThereValidSurveyTemplate()))) {
				return true;
			}

			return false;
		}

		// Verifica se il corso è terminato

		// Verifica se l'utente è in stato presente e c'è una Survey da iniziare
		$scope.isUserPresentAndSurveyToStart = () => {
			if ($scope.isUserStatusPresent() && $scope.isThereValidSurveyTemplate() && !$scope.course.surveyStatus) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente è in stato presente e c'è una Survey da continuare
		$scope.isUserPresentAndSurveyToContinue = () => {
			if ($scope.isUserStatusPresent() && $scope.isThereValidSurveyTemplate() && $scope.course.surveyStatus && ($scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.STARTED || $scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.NOT_PASSED)) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente è in stato presente e c'è una Survey non passata
		$scope.isUserPresentAndSurveyNotPassed = () => {
			if ($scope.isUserStatusPresent() && $scope.isThereValidSurveyTemplate() && $scope.course.surveyStatus && $scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.NOT_PASSED) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente ha l'invito pendente
		$scope.isUserStatusInvited = () => {
			if ($scope.course.userStatus && $scope.course.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_INVITED) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente è confermato
		$scope.isUserStatusConfirmed = () => {
			if ($scope.course.userStatus && $scope.course.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_CONFIRMED) {
				return true;
			}

			return false;
		}

		// Verifica se l'utente è rifiutato
		$scope.isUserStatusRefused = () => {
			if ($scope.course.userStatus && $scope.course.userStatus == $scope.bgacademyApplicationData.constants.COURSE_USER_STATUS_CANCELLED) {
				return true;
			}

			return false;
		}

		// Verifica se c'è un template valido per la survey
		$scope.isThereValidSurveyTemplate = () => {
			if ($scope.course.templateStatus && $scope.course.templateStatus == $scope.bgacademyApplicationData.constants.VALID) {
				return true;
			}

			return false;
		}

		// Verifica se la survey è iniziata, o è pronta per essere iniziata o non è stata passata)
		$scope.isSurveyReady = () => {
			if ($scope.isThereValidSurveyTemplate() && ((!$scope.course.surveyStatus) || ($scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.STARTED || $scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.NOT_PASSED))) {
				return true;
			}

			return false;
		}

		//Verifica se l'edizione è scaduta
		$scope.isStopDateExceeded = () => {
			let now = new Date();
			if (now > $scope.course.stopDate) {
				return true;
			}

			return false;
		}

		//Verifica se l'edizione è scaduta
		$scope.isEndDateExceeded = () => {
			let now = new Date();
			if (now > new Date( new Date($scope.course.endDate).getTime() + (new Date().getTimezoneOffset() * 60 * 1000))) {
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore arancione, cioè nel caso di corso in aula e lo stato dell'utente non è presente
		$scope.enableOrangeColor = () => {
			if (!$scope.isCourseOnline && (!$scope.isUserStatusPresent() || ($scope.isUserStatusPresent() && $scope.isSurveyReady()))) {
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore rosso, cioè nel caso di corso online e lo stato dell'utente non è presente
		$scope.enableRedColor = () => {
			if ($scope.isCourseOnline && (!$scope.isUserStatusPresent() || ($scope.isUserStatusPresent() && $scope.isSurveyReady()))) {
				return true;
			}

			return false;
		}

		// Verifica se abilitare il colore grigio, cioè qualora ci sia una verifica completata o non ci sia affatto (quindi neppure un template survey) oppure il corso sia scaduto e l'utente si trovi negli stati INVITATO, CONFERMANTO o RIFIUTATO
		$scope.enableGreyColor = () => {
			if ($scope.course.objDisabled == true || (($scope.isUserStatusInvited() || $scope.isUserStatusConfirmed() || $scope.isUserStatusRefused()) && $scope.isStopDateExceeded()) || $scope.isEndDateExceeded() || ($scope.isUserStatusPresent() && (($scope.course.surveyStatus && $scope.course.surveyStatus == $scope.bgacademyApplicationData.constants.PASSED) || !$scope.isThereValidSurveyTemplate()))) {
				return true;
			}

			return false;
		}

		// Verifico gli attributi dell'oggetto
		if ($scope.course) {
			// Verifico il tipo di oggetto
			if (!$scope.isCourseOnline) {
				$scope.course.courseType = 'IN AULA';
			} else if ($scope.isCourseOnline) {
				$scope.course.courseType = 'ONLINE';
			}

			if ($scope.course.courseAttributes) {
				let courseAttributes = $scope.course.courseAttributes;
				let mifidValue = null;
				let ivassValue = null;
				let creditValue = null;
				for (let k = 0; k < courseAttributes.length; k++) {
					// Verifico se è una competenza soft
					if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_SKILL) {
						$scope.isSoftSkill = true;
					}
					// Verifico se è una competenza tecnica
					if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.TECHNICAL_SKILL) {
						$scope.isTechSkill = true;
					}

					if (courseAttributes[k].courseAttributeType) {
						if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.IVASS_TYPE) {
							ivassValue = parseFloat(courseAttributes[k].courseAttributeValue);
							$scope.isIvass = true;
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EFA_A_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EFA_A;
							creditValue = parseFloat(courseAttributes[k].courseAttributeValue);
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EFA_B_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EFA_B;
							creditValue = parseFloat(courseAttributes[k].courseAttributeValue);
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EFP_TYPE) {
							$scope.creditType = BgacademyApplicationData.constants.EFP;
							creditValue = parseFloat(courseAttributes[k].courseAttributeValue);
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.EXTRA_TYPE) {
							$scope.creditType = null;
							creditValue = null;
						} else if (courseAttributes[k].courseAttributeType == BgacademyApplicationData.constants.MIFID_II_TYPE) {
							mifidValue = parseFloat(courseAttributes[k].courseAttributeValue);
							if (mifidValue) {
								$scope.isMifid = true;
							}
						} 
					}

					// Recupero i tag - se non c'è il crossReferenceObject significa che non è un vero tag, quindi lo salto
					if (courseAttributes[k].crossReferenceObject) {
						if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.TECH_COMPETENCES) {
							$scope.techCompetences.push(courseAttributes[k].crossReferenceObject.title);
						} else if (courseAttributes[k].attributeType == BgacademyApplicationData.constants.SOFT_COMPETENCES) {
							$scope.softCompetences.push(courseAttributes[k].crossReferenceObject.title);
						}
					}
				}

				// Se è mifid, traduco l'etichetta
				if ($scope.isMifid) {
					if (mifidValue === 1) {
						$scope.mifidValue = mifidValue + " " + $translate.instant("generic.time.HOUR");
					} else {
						$scope.mifidValue = mifidValue + " " + $translate.instant("generic.time.HOURS");
					}
				}

				// Stessa cosa per ivass
				if ($scope.isIvass) {
					if (ivassValue === 1) {
						$scope.ivassValue = ivassValue + " " + $translate.instant("generic.CREDIT");
					} else {
						$scope.ivassValue = ivassValue + " " + $translate.instant("generic.CREDITS");
					}
				}

				// Stessa cosa per gli efa
				if (creditValue) {
					if (creditValue === 1) {
						$scope.creditValue = creditValue + " " + $translate.instant("generic.CREDIT");
					} else {
						$scope.creditValue = creditValue + " " + $translate.instant("generic.CREDITS");
					}
				}
			}

			// Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
			/*for(let d = 0; d < $scope.item.engagements.length; d++){
				if($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_OPEN){
					// Oggetto aperto
					$scope.isItemOpen = true;
				}
				
				if($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_STARTED){
					// Recupero la percentuale
					for(let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++){
						if($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE'){
							$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
							break;
						}
					}
				}
				
				if($scope.item.engagements[d].moduleName == BgacademyApplicationData.constants.ITEM && $scope.item.engagements[d].eventName == BgacademyApplicationData.constants.EVENT_ITEM_CONSUMED){
					// Recupero la percentuale
					for(let z = 0; z < $scope.item.engagements[d].engagementDetails.length; z++){
						if($scope.item.engagements[d].engagementDetails[z].detailKey == 'PERCENTAGE'){
							$scope.percentageItem = $scope.item.engagements[d].engagementDetails[z].detailValue;
							break;
						}
					}
				}
			}*/

			/* Se l'oggetto non è aperto, nè iniziato nè completato allora cerco i suggeriti
			if(!$scope.isItemOpen && !$scope.percentageItem){
				// Verifico se ci sono suggeriti (visibility = 'suggestion'), e se ci sono li aggiungo alla lista
				for(let j = 0; j < $scope.item.itemVisibilities.length; j++){
					// Se è stato suggerito dall'amministrazione, ha la precedenza
					if($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION && $scope.item.itemVisibilities[j].fromAdmin == true){
						$scope.suggestedFromAdmin = true;
					} else if($scope.item.itemVisibilities[j].visibilityType == BgacademyApplicationData.constants.SUGGESTION){
						// Verifico se ho i permessi per visualizzarlo
						let groupFound: boolean = false;
						for(let z = 0; z < $scope.globalApplicationData.userGroups.length; z++) {
							if ($scope.globalApplicationData.userGroups[z].groupId == $scope.item.itemVisibilities[j].allowedGroupId) {
								groupFound = true;
								break;
							}
						}
						if(groupFound || ($scope.globalApplicationData.jwtPayload.user.userId == $scope.item.itemVisibilities[j].allowedUserId)){
							$scope.hasSuggested = true;

							// Salvo chi me l'ha suggerito
							$scope.suggestedPerson = $scope.item.itemVisibilities[j].userObject;
						}
					}
				}
			}*/

		}

		// Porta alla pagina di dettaglio del corso selezionato
		$scope.goToCourseDetail = (courseId: string, courseDateId: string) => {
			$state.go("app.bgacademyApp.courseDetail", { courseId: courseId, courseDateId: courseDateId });
		}
		
		// Porta alla pagina di dettaglio del corso selezionato
		$scope.goToWebinarDetail = (webinarId: string) => {
			$state.go("app.bgacademyApp.webinarDetail", { webinarId: webinarId });
        }
	}
});